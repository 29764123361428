import { Box, Img } from "@chakra-ui/react";
import React from "react";
import logo from "../assets/export_addis_logo_ordnwx.png"
export const Logo = ({ color }) => {
    return (
        <Box>
            <Img
                maxH={{ base: "42px", sm: "60px" }}
                minH={{ base: "42px", md: "60px" }}
                src={logo}
            />
        </Box>
    );
};
